import React from 'react';
import './index.scss';
const Loading = () => {
  return (
    /*   <div className='loading'>
      <div className='arc'></div>
      <div className='arc'></div>
      <div className='arc'></div>
    </div> */

    <div className='main loading-spinner'>
      <div className='loading-div'>
        {/* <!-- 8 --> */}
        <div className='loader loader--style8' title='7'>
          <svg
            version='1.1'
            id='Layer_1'
            x='0px'
            y='0px'
            width='100px'
            height='100px'
            viewBox='0 0 24 30'
          >
            <rect x='0' y='10' width='4' height='10' fill='#333' opacity='0.2'>
              <animate
                attributeName='opacity'
                attributeType='XML'
                values='0.2; 1; .2'
                begin='0s'
                dur='0.6s'
                repeatCount='indefinite'
              />
              <animate
                attributeName='height'
                attributeType='XML'
                values='10; 20; 10'
                begin='0s'
                dur='0.6s'
                repeatCount='indefinite'
              />
              <animate
                attributeName='y'
                attributeType='XML'
                values='10; 5; 10'
                begin='0s'
                dur='0.6s'
                repeatCount='indefinite'
              />
            </rect>
            <rect x='8' y='10' width='4' height='10' fill='#333' opacity='0.2'>
              <animate
                attributeName='opacity'
                attributeType='XML'
                values='0.2; 1; .2'
                begin='0.15s'
                dur='0.6s'
                repeatCount='indefinite'
              />
              <animate
                attributeName='height'
                attributeType='XML'
                values='10; 20; 10'
                begin='0.15s'
                dur='0.6s'
                repeatCount='indefinite'
              />
              <animate
                attributeName='y'
                attributeType='XML'
                values='10; 5; 10'
                begin='0.15s'
                dur='0.6s'
                repeatCount='indefinite'
              />
            </rect>
            <rect x='16' y='10' width='4' height='10' fill='#333' opacity='0.2'>
              <animate
                attributeName='opacity'
                attributeType='XML'
                values='0.2; 1; .2'
                begin='0.3s'
                dur='0.6s'
                repeatCount='indefinite'
              />
              <animate
                attributeName='height'
                attributeType='XML'
                values='10; 20; 10'
                begin='0.3s'
                dur='0.6s'
                repeatCount='indefinite'
              />
              <animate
                attributeName='y'
                attributeType='XML'
                values='10; 5; 10'
                begin='0.3s'
                dur='0.6s'
                repeatCount='indefinite'
              />
            </rect>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Loading;
