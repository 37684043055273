import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import ScrollToTop from './helpers/ScrollToTop';
import Loading from './components/LoadingSpinner';

import './globals.scss';
// const Loading = lazy(() => import('./components/LoadingSpinner'));

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Solutions = lazy(() => import('./pages/Solutions'));
const Contact = lazy(() => import('./pages/Contact'));

function App() {
  return (
    <div className='App'>
      <Suspense fallback={<Loading />}>
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/solutions' element={<Solutions />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
        </ScrollToTop>
      </Suspense>
    </div>
  );
}

export default App;
